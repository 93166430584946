import React from 'react';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import useRegister from '../hooks/useRegister';
import CompanyForm from './CompanyForm';
import MultiStepForm from './MultiStepForm';

const Register: React.FC = () => {
  const {
    isCompany,
    step,
    formData,
    successModalOpen,
    errorModalOpen,
    passwordValidation,
    handleChange,
    handleNextStep,
    handlePrevStep,
    handleSubmit,
    handleCloseSuccessModal,
    handleCloseErrorModal,
    validatePassword,
  } = useRegister();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 700 }}>
      {step === 1 && (
        <>
          <Typography variant="h6" gutterBottom>
            ¿Eres una Persona Jurídica o una Persona Física?
          </Typography>
          {/* <Button variant="outlined" onClick={() => handleNextStep() && (isCompany || handleNextStep())}>
            Persona Jurídica
          </Button> */}
          <Button variant="outlined" onClick={handleNextStep}>
            Persona Física
          </Button>
        </>
      )}

      {isCompany && step > 1 && (
        <>
          {/* <CompanyForm
            formData={formData}
            handleChange={handleChange}
          /> */}
          <Button variant="contained" color="primary" fullWidth sx={{ mt: 3 }} onClick={handleSubmit}>
            Enviar
          </Button>
        </>
      )}

      {!isCompany && step > 1 && (
        <>
          {/* Renderizamos MultiStepForm */}
          <MultiStepForm
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            validatePassword={validatePassword} // Agregamos esta prop
            passwordValidation={passwordValidation} // Agregamos esta prop
          />

        </>
      )}

      {/* Modal de Error */}
      <Dialog open={errorModalOpen} onClose={handleCloseErrorModal} maxWidth="sm" fullWidth>
        <DialogContent sx={{ textAlign: 'center', p: 4 }}>
          <Box
            sx={{
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 80,
              height: 80,
              borderRadius: '50%',
              backgroundColor: '#ffe6e6',
              mb: 2,
              mx: 'auto',
            }}
          >
            <ErrorOutlineIcon sx={{ color: '#cc0000', fontSize: 50 }} />
          </Box>
          <Typography variant="h5" sx={{ color: '#cc0000', mb: 2 }}>
            Error en la contraseña
          </Typography>
          {/* Lista de errores */}
          {/* ... */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorModal} sx={{ color: '#cc0000' }}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de Éxito */}
      <Dialog open={successModalOpen} onClose={handleCloseSuccessModal} maxWidth="sm" fullWidth>
        <DialogContent sx={{ textAlign: 'center', p: 4 }}>
          <Box
            sx={{
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 80,
              height: 80,
              borderRadius: '50%',
              backgroundColor: '#e6f7e6',
              mb: 2,
              mx: 'auto',
            }}
          >
            <CheckCircleIcon sx={{ color: '#2e7d32', fontSize: 50 }} />
          </Box>
          <Typography variant="h5" sx={{ color: '#2e7d32', mb: 2 }}>
            ¡Registro Exitoso!
          </Typography>
          <Typography variant="body1" sx={{ color: '#2e7d32' }}>
            Revisa tu correo para confirmar tu cuenta y completar el registro.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseSuccessModal}
            sx={{
              color: '#2e7d32',
              fontWeight: 'bold',
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Register;
