import { useState } from 'react';

const useRegister = () => {
  const [isCompany, setIsCompany] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone_number: '',
    tax_id: '',
    wallet_address: '',
    identity_document_url: '',
    country: '', 
    postal_code: '', 
    address: '', 
    address_number: '',
    is_uiff: false,
    is_exposed: false,
  });
  const [showPassword, setShowPassword] = useState(false);

  // Estado para modales
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    const checked = (e.target as HTMLInputElement).checked;
  
    setFormData((prev) => ({
      ...prev,
      [name]: name === 'address_number' ? (value ? parseInt(value, 10) || '' : '') : (type === 'checkbox' ? checked : value),
    }));
  };
  

  
  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleNextStep = () => setStep((prev) => prev + 1);
  const handlePrevStep = () => setStep((prev) => prev - 1);
  const handleSelectType = (type: string) => {
    setIsCompany(type === 'company');
    setStep(2);
  };

  const validatePassword = (password: string): boolean => {
    const length = password.length >= 8;
    const uppercase = /[A-Z]/.test(password);
    const lowercase = /[a-z]/.test(password);
    const number = /\d/.test(password);
    const specialChar = /[@$!%*?&#]/.test(password);

    setPasswordValidation({ length, uppercase, lowercase, number, specialChar });

    return length && uppercase && lowercase && number && specialChar;
  };

  const validatePasswordsMatch = (): boolean => {
    const match = formData.password === formData.confirmPassword;
    setPasswordsMatch(match);
    return match;
  };


  const handleSubmit = async () => {
    if (!validatePassword(formData.password)) {
      setErrorModalOpen(true);
      return;
    }

    if (!validatePasswordsMatch()) {
        setErrorModalOpen(true);
        return;
      }

    if (formData.password !== formData.confirmPassword) {
      setPasswordValidation({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        specialChar: false,
      });
      setErrorModalOpen(true);
      return;
    }

    try {
      const url = isCompany
        ? 'https://app.atomico3.io/api/v1/register-company'
        : 'https://app.atomico3.io/api/v1/register';

      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessModalOpen(true);
      } else {
        const error = await response.json();
        setPasswordValidation({
          length: false,
          uppercase: false,
          lowercase: false,
          number: false,
          specialChar: false,
        });
        setErrorModalOpen(true);
      }
    } catch (err) {
      setErrorModalOpen(true);
    }
  };

  const handleCloseSuccessModal = () => setSuccessModalOpen(false);
  const handleCloseErrorModal = () => setErrorModalOpen(false);

  return {
    isCompany,
    step,
    formData,
    showPassword,
    successModalOpen,
    errorModalOpen,
    passwordValidation,
    handleChange,
    toggleShowPassword,
    handleNextStep,
    passwordsMatch,
    handlePrevStep,
    handleSelectType,
    validatePassword,
    handleSubmit,
    handleCloseSuccessModal,
    handleCloseErrorModal,
  };
};

export default useRegister;
