import React, { useState, useEffect } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, Pagination, Card, CardContent, Button, Modal } from '@mui/material';
import Web3 from 'web3';

const UnstakeForm: React.FC = () => {
  const [stakes, setStakes] = useState<any[]>([]);
  const [balance, setBalance] = useState('');
  const [processingStakeIndex, setProcessingStakeIndex] = useState<number | null>(null); // Índice en proceso
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [processingModalOpen, setProcessingModalOpen] = useState(false);
  const [resultModal, setResultModal] = useState<{ open: boolean; message: string }>({ open: false, message: '' });
  const [selectedStake, setSelectedStake] = useState<any | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS_STAKING;
  const contractABI = JSON.parse(process.env.REACT_APP_CONTRACT_ABI || '[]');

  const fetchUserStakes = async () => {
    try {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({ method: 'eth_requestAccounts' });

        const accounts = await web3.eth.getAccounts();
        const userAddress = accounts[0];

        const stakingContract = new web3.eth.Contract(contractABI, contractAddress);
        const userStakes = await stakingContract.methods.getUserStakes(userAddress).call();
        const validUserStakes = Array.isArray(userStakes) ? userStakes : [];

        const formattedStakes = validUserStakes.map((stake: any, index: number) => ({
          index,
          amount: web3.utils.fromWei(stake.amount.toString(), 'ether'),
          stakingDays: Number(stake.stakingDays),
          startTime: new Date(Number(stake.startTime) * 1000),
          reward: web3.utils.fromWei(stake.reward.toString(), 'ether'),
          active: stake.active,
        }));

        setStakes(formattedStakes);
      } else {
        alert('MetaMask no está instalado. Por favor, instálalo para continuar.');
      }
    } catch (error) {
      console.error('Error al obtener los stakes:', error);
    }
  };

  useEffect(() => {
    fetchUserStakes();
  }, []);

  const handleConfirmUnstake = async () => {
    if (!selectedStake) return;
  
    setProcessingStakeIndex(selectedStake.index);
    setConfirmModalOpen(false);
    setProcessingModalOpen(true);
  
    try {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({ method: 'eth_requestAccounts' });
  
        const accounts = await web3.eth.getAccounts();
        const userAddress = accounts[0];
        const stakingContract = new web3.eth.Contract(contractABI, contractAddress);
  
        // Obtener gasPrice actual y ajustarlo un 15%
        const gasPrice = await web3.eth.getGasPrice();
        const increasedGasPrice = (BigInt(gasPrice) * BigInt(115)) / BigInt(100); // Incremento del 15%
  
        // Estimar gas y ajustarlo un 20%
        const gasEstimate = await stakingContract.methods
          .unstake(selectedStake.index)
          .estimateGas({ from: userAddress });
        const increasedGasEstimate = (BigInt(gasEstimate) * BigInt(120)) / BigInt(100); // Incremento del 20%
  
        // Enviar transacción con valores ajustados
        await stakingContract.methods.unstake(selectedStake.index).send({
          from: userAddress,
          gas: increasedGasEstimate.toString(), // Convertir BigInt a string
          gasPrice: increasedGasPrice.toString(), // Convertir BigInt a string
        });
  
        setProcessingModalOpen(false);
        setResultModal({ open: true, message: 'Unstake realizado con éxito' });
        fetchUserStakes(); // Recarga los stakes
      }
    } catch (error) {
      console.error('Error realizando el unstake:', error);
      setProcessingModalOpen(false);
      setResultModal({ open: true, message: 'Hubo un error al realizar el unstake.' });
    }
    setProcessingStakeIndex(null);
  };
  

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const paginatedStakes = stakes.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <>
      <Card sx={{ maxWidth: '800px', margin: '20px auto', p: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Stakes Activos
          </Typography>

          {stakes.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Cantidad</TableCell>
                      <TableCell>Días de Staking</TableCell>
                      <TableCell>Fecha de Inicio</TableCell>
                      <TableCell>Recompensa</TableCell>
                      <TableCell>Activo</TableCell>
                      <TableCell>Acción</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedStakes.map((stake) => {
                      const currentDate = new Date();
                      const endDate = new Date(stake.startTime.getTime() + stake.stakingDays * 24 * 60 * 60 * 1000);
                      const isPeriodEnded = currentDate >= endDate;

                      return (
                        <TableRow key={stake.index}>
                          <TableCell>{stake.amount} AT3</TableCell>
                          <TableCell>{stake.stakingDays}</TableCell>
                          <TableCell>{stake.startTime.toLocaleString()}</TableCell>
                          <TableCell>{stake.reward} AT3</TableCell>
                          <TableCell>{stake.active ? 'Sí' : 'No'}</TableCell>
                          <TableCell>
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() => {
                                setSelectedStake(stake);
                                setConfirmModalOpen(true);
                              }}
                              disabled={!isPeriodEnded || !stake.active || processingStakeIndex !== null}
                            >
                              {processingStakeIndex === stake.index ? 'Procesando...' : 'Unstake'}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                  count={Math.ceil(stakes.length / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Box>
            </>
          ) : (
            <Typography>No tienes stakes activos.</Typography>
          )}
        </CardContent>
      </Card>

      {/* Modal de confirmación */}
      <Modal open={confirmModalOpen} onClose={() => setConfirmModalOpen(false)}>
        <Box sx={{ p: 4, backgroundColor: 'white', borderRadius: '8px', maxWidth: '400px', margin: 'auto', mt: 10 }}>
          <Typography variant="h6" gutterBottom>
            Confirmar Unstake
          </Typography>
          <Typography>
            Vas a recibir:{selectedStake?.amount} AT3, y {selectedStake?.reward} AT3 de recompensa. ¿Deseas continuar?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
            <Button onClick={() => setConfirmModalOpen(false)}>Cancelar</Button>
            <Button onClick={handleConfirmUnstake} variant="contained" color="primary">
              Confirmar
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Modal de "En proceso" */}
      <Modal open={processingModalOpen}>
        <Box sx={{ p: 4, backgroundColor: 'white', borderRadius: '8px', maxWidth: '400px', margin: 'auto', mt: 10 }}>
          <Typography variant="h6" gutterBottom>
            En proceso
          </Typography>
          <Typography>Revisa MetaMask para confirmar la transacción.</Typography>
        </Box>
      </Modal>

      {/* Modal de resultado */}
      <Modal open={resultModal.open} onClose={() => window.location.reload()}>
        <Box sx={{ p: 4, backgroundColor: 'white', borderRadius: '8px', maxWidth: '400px', margin: 'auto', mt: 10 }}>
          <Typography variant="h6" gutterBottom>
            Resultado
          </Typography>
          <Typography>{resultModal.message}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button onClick={() => window.location.reload()} variant="contained" color="primary">
              Aceptar
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UnstakeForm;
