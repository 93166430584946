import React, { useEffect } from 'react';
import { Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import Home from '../../pages/Home/Home';
import Admin from '../../pages/Admin/Admin';
import StakingPlus from '../../pages/Staking/StakingPlus';
import Auth from '../../pages/Login/Auth';
import ProtectedRoute from './ProtectedRoute';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import PrivateContent from '../PrivateContent';
import PrivateAuth from '../../pages/Login/Components/PrivateAuth';
import ConfirmAccount from '../../pages/Login/Components/ConfirmAccount';

interface RouteManagerProps {
  account: string | null;
  setAccount: React.Dispatch<React.SetStateAction<string | null>>;
}

const RouteManager: React.FC<RouteManagerProps> = ({ account, setAccount }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const publicRoutes = ['/auth', '/confirm_account'];
  
  useEffect(() => {
    const sessionToken = localStorage.getItem('sessionToken');

    // Redirige solo si no hay token y la ruta no es pública
    if (!sessionToken && !publicRoutes.includes(location.pathname)) {
      navigate('/auth');
    } else if (sessionToken && !account) {
      setAccount(sessionToken); // Establece el token como cuenta activa.
    }
  }, [navigate, account, setAccount, location.pathname]);

  const isAuthenticated = !!localStorage.getItem('sessionToken');

  return (
    <SwitchTransition>
      <CSSTransition key={location.key} timeout={300} classNames="fade">
        <Routes location={location}>
          {/* Rutas públicas */}
          <Route path="/auth" element={<Auth />} />
          <Route path="/confirm_account" element={<ConfirmAccount />} />
          
          {/* Ruta protegida usando PrivateAuth */}
          <Route element={<PrivateAuth isAuthenticated={isAuthenticated} redirectToLogin="/auth" />}>
            <Route path="/private/:contentId" element={<PrivateContent />} />
            <Route path="/admin" element={<Admin />} />
          </Route>
          
          {/* Redirección para la URL "/whitepaper" */}
          <Route path="/whitepapper" element={<Navigate to="/private/whitepapper" replace />} />

          {/* Rutas protegidas */}
          <Route 
            path="/" 
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} redirectTo="/auth">
                <Home account={account} setAccount={setAccount} />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/home" 
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} redirectTo="/auth">
                <Home account={account} setAccount={setAccount} />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/admin" 
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} redirectTo="/auth">
                <Admin />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/stakingplus" 
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} redirectTo="/auth">
                <StakingPlus />
              </ProtectedRoute>
            } 
          />

          {/* Ruta dinámica para contenido privado */}
          <Route
            path="/private/:contentId"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated} redirectTo="/auth">
                <PrivateContent />
              </ProtectedRoute>
            }
          />
        </Routes>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default RouteManager;
