import { SxProps, Theme } from '@mui/material';

import linesImage from '../../assets/images/lineas.png';
import linesImage2 from '../../assets/images/lineas2.png';
import bgImagen from '../../assets/images/bgatomico.png';


export const authContainer: SxProps<Theme> = {
  position: 'relative', 
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  justifyContent: 'left',
  alignItems: 'center',
  minHeight: { xs: '100vh', md: '90vh' },
  padding: '20px',
  backgroundImage: `url(${bgImagen}), linear-gradient(45deg, #004AAD, #000024)`,
  backgroundSize: 'cover',
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat, no-repeat',
  animation: 'gradient 105s ease infinite',
  overflow: 'hidden', 
  zIndex: 1, 
  '@keyframes gradient': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' },
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-10%',
    left: '-10%',
    width: '150%',
    height: '150%',
    backgroundImage: `url(${linesImage2})`,
  backgroundSize: 'contain',

    opacity: 0.1,
    animation: 'rotateBackground 60s linear infinite',
    zIndex: 0, 
  },
  '@keyframes rotateBackground': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  '& .shooting-star': {
    position: 'absolute',
    width: '2px',
    height: '2px',
    backgroundColor: '#FFF',
    boxShadow: '0 0 10px 2px #FFF',
    borderRadius: '50%',
    animation: 'shootingStar 3s ease-in-out infinite',
    zIndex: 0, 
  },
  '@keyframes shootingStar': {
    '0%': {
      top: '-10%',
      left: '20%',
      opacity: 1,
      transform: 'translate(0, 0) scale(1)',
    },
    '100%': {
      top: '110%',
      left: '100%',
      opacity: 0,
      transform: 'translate(200%, 200%) scale(0.5)',
    },
  },
};

export const logoContainer: SxProps<Theme> = {
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const rotatingLogoStyle = {
  maxWidth: '300px',
  height: 'auto',
  objectFit: 'contain',
  animation: 'rotateLogo 5s ease-in-out infinite',
};

export const formContainer: SxProps<Theme> = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  textAlign: 'left',
  mt: { xs: 4, md: 0 },
 
  
};

export const modalStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', md: 800 },
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: ' 9px 9px 20px 0px;',
  p: 4,
};

export const formContent: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  maxWidth: 500,
  backgroundColor: 'white',
  paddingTop: 10,
  paddingLeft: 1,
  paddingRight: 1,
  paddingBottom: 10,
  borderRadius: 5,
  boxShadow: ' 9px 9px 20px 0px;',
};
