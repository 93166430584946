import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';

interface LogoutButtonProps {
  variant?: 'text' | 'outlined' | 'contained'; 
  color?: 'primary' | 'secondary' | 'inherit'; 
  fullWidth?: boolean; 
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ variant = 'contained', color = 'primary', fullWidth = false }) => {
  const { setSessionToken } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    setSessionToken(null); // Actualiza el estado global de sesión y elimina el token
    navigate('/auth'); // Redirige al usuario a la página de autenticación
  };

  return (
    <Button fullWidth={fullWidth} variant={variant} color={color} onClick={handleLogout}>
      Cerrar Sesión
    </Button>
  );
};

export default LogoutButton;
