import React from 'react';
import { Box, Button } from '@mui/material';
import logo from '../../../assets/images/logoAT3.png';
import ConnectWallet from '../../../components/ConnectWallet';

interface SidebarProps {
  account: string | null;
  setAccount: (account: string | null) => void;
  setActiveComponent: (component: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ account, setAccount, setActiveComponent }) => {
  return (
    <Box
      sx={{
        width: '250px',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 3,
        backgroundColor: '#051230', 
        color: 'white',
        borderRight: '1px solid #7d90ff63'
      }}
    >
      <img src={logo} alt="Logo" style={{ height: 60, marginBottom: 20 }} />

      {!account && <ConnectWallet onConnect={setAccount} />}
      {account && (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Button variant='outlined' fullWidth onClick={() => setActiveComponent('stake')}>Stake</Button>
          <Button variant='outlined' fullWidth onClick={() => setActiveComponent('unstake')}>Unstake</Button>
          <Button variant='outlined' fullWidth onClick={() => setActiveComponent('viewStake')}>Ver Staking</Button>
          <Button variant='outlined' fullWidth onClick={() => setActiveComponent('peerToPeer')}>Peer-to-Peer</Button>
          <Button variant='outlined' fullWidth onClick={() => setActiveComponent('viewPriceChart')}>viewPriceChart</Button>
          <Button variant='outlined' fullWidth onClick={() => setActiveComponent('buyAT3')}>Compra AT3</Button>
        </Box>
      )}
      <Button
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          color: '#051230',
          mt: 3,
          '&:hover': {
            backgroundColor: '#051230',
            color: 'white',
            
          },
        }}
        fullWidth
        onClick={() => window.location.href = 'https://atomico3.io'}
      >
        Volver al sitio web
      </Button>
    </Box>
    
  );
};

export default Sidebar;

