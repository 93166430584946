import React, { useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  Divider,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

interface MultiStepFormProps {
  formData: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  validatePassword: (password: string) => boolean;
  passwordValidation: {
    length: boolean;
    uppercase: boolean;
    lowercase: boolean;
    number: boolean;
    specialChar: boolean;
  };
}

const MultiStepForm: React.FC<MultiStepFormProps> = ({
  formData,
  handleChange,
  handleSubmit,
  validatePassword,
  passwordValidation,
}) => {
  const [step, setStep] = useState(1);
  const [acceptTerms, setAcceptTerms] = useState(false); // Estado para el checkbox de términos y condiciones
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleNextStep = () => setStep((prev) => prev + 1);
  const handlePrevStep = () => setStep((prev) => prev - 1);
  const toggleShowPassword = () => setShowPassword((prev) => !prev);
  const toggleShowConfirmPassword = () => setShowConfirmPassword((prev) => !prev);

  return (
    <Box>
      {/* Paso 1: Datos del usuario */}
      {step === 1 && (
        <>
          <Typography variant="h6" gutterBottom>
            Paso 1: Datos del usuario
          </Typography>
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
            <TextField
              label="Nombre"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
            <TextField
              label="Apellido"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
            <TextField
              label="CUIT"
              name="tax_id"
              value={formData.tax_id}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
            <TextField
              label="Correo Electrónico"
              name="email"
              value={formData.email}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
            <TextField
              label="Número de Teléfono"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ textAlign: 'right' }}>
            <Button variant="contained" onClick={handleNextStep}>
              Siguiente
            </Button>
          </Box>
        </>
      )}

      {/* Paso 2: Datos del domicilio */}
      {step === 2 && (
        <>
          <Typography variant="h6" gutterBottom>
            Paso 2: Datos del domicilio
          </Typography>
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
            <TextField
              label="Dirección"
              name="address"
              value={formData.address}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
            <TextField
            label="Número de Dirección"
            name="address_number"
            type="number"
            value={formData.address_number}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            />
            <TextField
              label="Código Postal"
              name="postal_code"
              value={formData.postal_code}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
            <TextField
              label="País"
              name="country"
              value={formData.country}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={handlePrevStep}>
              Anterior
            </Button>
            <Button variant="contained" onClick={handleNextStep}>
              Siguiente
            </Button>
          </Box>
        </>
      )}

      {/* Paso 3: Contraseña y Documentación */}
      {step === 3 && (
  <>
    <Typography variant="h6" gutterBottom>
      Paso 3: Contraseña
    </Typography>
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: 2 }}>
      <TextField
        label="Contraseña"
        name="password"
        type={showPassword ? 'text' : 'password'}
        value={formData.password}
        onChange={(e) => {
          handleChange(e as React.ChangeEvent<HTMLInputElement>);
          validatePassword(e.target.value);
        }}
        variant="outlined"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={toggleShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Confirmar Contraseña"
        name="confirmPassword"
        type={showConfirmPassword ? 'text' : 'password'}
        value={formData.confirmPassword}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={toggleShowConfirmPassword}>
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Typography variant="body2" sx={{ mt: 2, fontWeight: 'bold' }}>
        La contraseña debe cumplir con los siguientes requisitos:
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            {passwordValidation.length ? (
              <CheckCircleIcon sx={{ color: 'success.main' }} />
            ) : (
              <CloseIcon sx={{ color: 'error.main' }} />
            )}
          </ListItemIcon>
          <ListItemText
            primary="Mínimo 8 caracteres"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            {passwordValidation.uppercase ? (
              <CheckCircleIcon sx={{ color: 'success.main' }} />
            ) : (
              <CloseIcon sx={{ color: 'error.main' }} />
            )}
          </ListItemIcon>
          <ListItemText
            primary="Una letra mayúscula"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            {passwordValidation.lowercase ? (
              <CheckCircleIcon sx={{ color: 'success.main' }} />
            ) : (
              <CloseIcon sx={{ color: 'error.main' }} />
            )}
          </ListItemIcon>
          <ListItemText
            primary="Una letra minúscula"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            {passwordValidation.number ? (
              <CheckCircleIcon sx={{ color: 'success.main' }} />
            ) : (
              <CloseIcon sx={{ color: 'error.main' }} />
            )}
          </ListItemIcon>
          <ListItemText
            primary="Un número"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            {passwordValidation.specialChar ? (
              <CheckCircleIcon sx={{ color: 'success.main' }} />
            ) : (
              <CloseIcon sx={{ color: 'error.main' }} />
            )}
          </ListItemIcon>
          <ListItemText
            primary="Un carácter especial (@$!%*?&#)"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      </List>
    </Box>
    <Divider sx={{ my: 2 }} />
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button variant="outlined" onClick={handlePrevStep}>
        Anterior
      </Button>
      <Button variant="contained" onClick={handleNextStep}>
        Siguiente
      </Button>
    </Box>
  </>
)}


      {/* Paso 4: Términos y condiciones */}
      {step === 4 && (
        <>
          <Typography variant="h6" gutterBottom>
            Paso 4: Términos y Condiciones
          </Typography>

        <Box>
          <FormControlLabel
            control={
              <Checkbox
                name="is_exposed"
                checked={formData.is_exposed}
                onChange={handleChange}
              />
            }
            label="Soy una persona políticamente expuesta"
          />
</Box>
<Box>

          <FormControlLabel
            control={
              <Checkbox
                name="is_uiff"
                checked={formData.is_uiff}
                onChange={handleChange}
              />
            }
            label="Soy obligado ante la UIFF"
          />
</Box>
<Box>

          <FormControlLabel
            control={
              <Checkbox
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
              />
            }
            label="Soy mayor de edad y acepto los términos y condiciones"
          />
</Box>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={handlePrevStep}>
              Anterior
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={!acceptTerms}
            >
              Crear cuenta
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default MultiStepForm;
